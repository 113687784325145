import { getContext, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { get, pick } from 'lodash';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';

export const DEFAULT_AVERAGE_DELIVERY_TIME = 1800000;
const DEFAULT_UPDATE_ORDERS_INTERVAL = 60000;

const prepareSubCompanyData = (data) => {
    return {
        coordinate: {},
        ...data
    };
};

// WATCHER
export function* subCompanySaga() {
    yield takeLatest(actions.getSubCompanyList, getSubCompanyList);
    yield takeLatest(actions.getCurrentAdminSuccess, getSubCompanyList);
    yield takeEvery(actions.setCouriersFilters, handleSomeCompanyFilterChanged);
    yield takeEvery(actions.updateSubCompany, updateSubCompany);
    yield takeEvery(actions.createSubCompany, createSubCompany);
    yield takeEvery(actions.deleteSubCompany, deleteSubCompany);
}

export function* getSubCompanyList({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const companyId = get(payload, 'companyId');
        if (!companyId) {
            yield put(actions.getSubCompanyListFailure('It is impossible to request sub list for companyId eq 0'));
            return;
        }

        const data = { companyId: companyId, withDeleted: false };

        const res = yield fastCityService.request(FC_REQUESTS.GET_SUB_COMPANY_LIST, data);
        const subCompanies = get(res, 'body.subCompany', []);

        const subCompaniesWithStringAddress = subCompanies.map((subCompany) => ({
            ...subCompany,
            address: new TextDecoder().decode(subCompany.address)
        }));

        yield put(
            actions.getSubCompanyListSuccess(subCompaniesWithStringAddress.map((sc) => prepareSubCompanyData(sc)))
        );
    } catch (err) {
        console.log(err, 'err');
        yield put(actions.getSubCompanyListFailure(`${err}`));
    }
}

export function* updateSubCompany({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const subCompany = pick(payload, [
            'coordinate',
            'companyId',
            'externalSubCompanyId',
            'address',
            'averageDeliveryTime'
        ]);

        const addressToBytes = new TextEncoder().encode(subCompany.address);
        const data = { ...subCompany, address: addressToBytes, averageDeliveryTime: 150000 };

        const res = yield fastCityService.request(FC_REQUESTS.UPDATE_SUB_COMPANY, data);
        const preparedData = prepareSubCompanyData(res.body);

        const preparedDataStringAddress = { ...preparedData, address: new TextDecoder().decode(preparedData.address) };

        yield put(actions.updateSubCompanySuccess(preparedDataStringAddress));
    } catch (err) {
        yield put(actions.updateSubCompanyFailure(`${err}`));
    }
}

export function* createSubCompany({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const subCompany = pick(payload, ['companyId', 'externalSubCompanyId', 'coordinate', 'address']);

        const addressToBytes = new TextEncoder().encode(subCompany.address);

        const data = {
            ...subCompany,
            address: addressToBytes,
            averageDeliveryTime: DEFAULT_AVERAGE_DELIVERY_TIME,
            updateOrdersInterval: DEFAULT_UPDATE_ORDERS_INTERVAL
        };

        const res = yield fastCityService.request(FC_REQUESTS.CREATE_SUB_COMPANY, data);
        const preparedData = prepareSubCompanyData(res.body);

        const preparedDataStringAddress = {
            ...preparedData,
            address: new TextDecoder().decode(preparedData.address),
            ordersLatitudeLongitudeSwap: false
        };

        yield put(actions.createSubCompanySuccess(preparedDataStringAddress));
    } catch (err) {
        console.error(err);
        yield put(actions.createSubCompanyFailure(`${err}`));
    }
}

export function* deleteSubCompany({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const subCompany = pick(payload, ['companyId', 'externalSubCompanyId']);

        const res = yield fastCityService.request(FC_REQUESTS.DELETE_SUB_COMPANY, subCompany);
        const preparedData = prepareSubCompanyData(res.body);

        yield put(actions.deleteSubCompanySuccess(preparedData));
    } catch (err) {
        console.error(err);
        yield put(actions.deleteSubCompanyFailure(`${err}`));
    }
}

function* handleSomeCompanyFilterChanged({ payload }) {
    if (payload?.companyId) {
        yield put(actions.getSubCompanyList({ companyId: payload.companyId }));
    }
}
