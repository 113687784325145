import { createAction } from '@reduxjs/toolkit';

// action - account reducer
export const login = createAction('LOGIN');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');
export const loginReset = createAction('LOGIN_STATUS_RESET');
export const logout = createAction('LOGOUT');

export const resetPassword = createAction('RESET_PASSWORD');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordFailure = createAction('RESET_PASSWORD_FAILURE');
export const resetPasswordReset = createAction('RESET_PASSWORD_STATUS_RESET');

export const activate = createAction('ACTIVATE');
export const activateSuccess = createAction('ACTIVATE_SUCCESS');
export const activateFailure = createAction('ACTIVATE_FAILURE');
export const activateReset = createAction('ACTIVATE_STATUS_RESET');

export const WSConnectionOpen = createAction('WS_CONNECTION_OPEN');
export const WSConnectionClose = createAction('WS_CONNECTION_CLOSE');
export const WSConnectionOnError = createAction('WS_CONNECTION_ON_ERROR');

// API - ADMINS
export const getCurrentAdmin = createAction('GET_CURRENT_ADMIN');
export const getCurrentAdminSuccess = createAction('GET_CURRENT_ADMIN_SUCCESS');
export const getCurrentAdminFailure = createAction('GET_CURRENT_ADMIN_FAILURE');

export const gotAdminData = createAction('GOT_ADMIN_DATA');

export const getAdminList = createAction('GET_ADMIN_LIST');
export const getAdminListSuccess = createAction('GET_ADMIN_LIST_SUCCESS');
export const getAdminListFailure = createAction('GET_ADMIN_LIST_FAILURE');

export const createAdmin = createAction('CREATE_ADMIN');
export const createAdminSuccess = createAction('CREATE_ADMIN_SUCCESS');
export const createAdminFailure = createAction('CREATE_ADMIN_FAILURE');
export const createAdminReset = createAction('CREATE_ADMIN_RESET');

export const updateAdmin = createAction('UPDATE_ADMIN');
export const updateAdminSuccess = createAction('UPDATE_ADMIN_SUCCESS');
export const updateAdminFailure = createAction('UPDATE_ADMIN_FAILURE');
export const updateAdminReset = createAction('UPDATE_ADMIN_RESET');

export const deleteAdmin = createAction('DELETE_ADMIN');
export const deleteAdminSuccess = createAction('DELETE_ADMIN_SUCCESS');
export const deleteAdminFailure = createAction('DELETE_ADMIN_FAILURE');
export const deleteAdminReset = createAction('DELETE_ADMIN_RESET');

// API - COMPANIES
export const getCurrentCompany = createAction('GET_CURRENT_COMPANY');
export const getCurrentCompanySuccess = createAction('GET_CURRENT_COMPANY_SUCCESS');
export const getCurrentCompanyFailure = createAction('GET_CURRENT_COMPANY_FAILURE');

export const getCompanyList = createAction('GET_COMPANY_LIST');
export const getCompanyListSuccess = createAction('GET_COMPANY_LIST_SUCCESS');
export const getCompanyListFailure = createAction('GET_COMPANY_LIST_FAILURE');

export const createCompany = createAction('CREATE_COMPANY');
export const createCompanySuccess = createAction('CREATE_COMPANY_SUCCESS');
export const createCompanyFailure = createAction('CREATE_COMPANY_FAILURE');
export const createCompanyReset = createAction('CREATE_COMPANY_RESET');

export const updateCompany = createAction('UPDATE_COMPANY');
export const updateCompanySuccess = createAction('UPDATE_COMPANY_SUCCESS');
export const updateCompanyFailure = createAction('UPDATE_COMPANY_FAILURE');
export const updateCompanyReset = createAction('UPDATE_COMPANY_RESET');

export const deleteCompany = createAction('DELETE_COMPANY');
export const deleteCompanySuccess = createAction('DELETE_COMPANY_SUCCESS');
export const deleteCompanyFailure = createAction('DELETE_COMPANY_FAILURE');
export const deleteCompanyReset = createAction('DELETE_COMPANY_RESET');

// COURIERS FILTERS
export const setCouriersFilters = createAction('SET_COURIERS_FILTERS');
export const toggleConnectedOnlyCourierFilter = createAction('TOGGLE_CONNECTED_ONLY_COURIER_FILTER');

// API - COURIER EXTERNAL SUB COMPANIES (RESTAURANTS)
export const getSubCompanyList = createAction('GET_SUB_COMPANY_LIST');
export const getSubCompanyListSuccess = createAction('GET_SUB_COMPANY_LIST_SUCCESS');
export const getSubCompanyListFailure = createAction('GET_SUB_COMPANY_LIST_FAILURE');

export const updateSubCompany = createAction('UPDATE_SUB_COMPANY');
export const updateSubCompanySuccess = createAction('UPDATE_SUB_COMPANY_SUCCESS');
export const updateSubCompanyFailure = createAction('UPDATE_SUB_COMPANY_FAILURE');
export const updateSubCompanyReset = createAction('UPDATE_SUB_COMPANY_RESET');

export const createSubCompany = createAction('CREATE_SUB_COMPANY');
export const createSubCompanySuccess = createAction('CREATE_SUB_COMPANY_SUCCESS');
export const createSubCompanyFailure = createAction('CREATE_SUB_COMPANY_FAILURE');
export const createSubCompanyReset = createAction('CREATE_SUB_COMPANY_RESET');

export const deleteSubCompany = createAction('DELETE_SUB_COMPANY');
export const deleteSubCompanySuccess = createAction('DELETE_SUB_COMPANY_SUCCESS');
export const deleteSubCompanyFailure = createAction('DELETE_SUB_COMPANY_FAILURE');
export const deleteSubCompanyReset = createAction('DELETE_SUB_COMPANY_RESET');

// API - SUB COMPANY ZONE
export const getSubCompanyZone = createAction('GET_SUB_COMPANY_ZONE');
export const getSubCompanyZoneSuccess = createAction('GET_SUB_COMPANY_ZONE_SUCCESS');
export const getSubCompanyZoneFailure = createAction('GET_SUB_COMPANY_ZONE_FAILURE');
export const getSubCompanyZoneReset = createAction('GET_SUB_COMPANY_ZONE_RESET');

export const createSubCompanyZone = createAction('CREATE_SUB_COMPANY_ZONE');
export const createSubCompanyZoneSuccess = createAction('CREATE_SUB_COMPANY_ZONE_SUCCESS');
export const createSubCompanyZoneFailure = createAction('CREATE_SUB_COMPANY_ZONE_FAILURE');
export const createSubCompanyZoneReset = createAction('CREATE_SUB_COMPANY_ZONE_RESET');

export const updateSubCompanyZoneUsingKml = createAction('UPDATE_SUB_COMPANY_ZONE');
export const updateSubCompanyZoneUsingKmlSuccess = createAction('UPDATE_SUB_COMPANY_ZONE_SUCCESS');
export const updateSubCompanyZoneUsingKmlFailure = createAction('UPDATE_SUB_COMPANY_ZONE_FAILURE');
export const updateSubCompanyZoneUsingKmlReset = createAction('UPDATE_SUB_COMPANY_ZONE_RESET');

export const deleteSubCompanyZone = createAction('DELETE_SUB_COMPANY_ZONE');
export const deleteSubCompanyZoneSuccess = createAction('DELETE_SUB_COMPANY_ZONE_SUCCESS');
export const deleteSubCompanyZoneFailure = createAction('DELETE_SUB_COMPANY_ZONE_FAILURE');
export const deleteSubCompanyZoneStatusReset = createAction('DELETE_SUB_COMPANY_ZONE_STATUS_RESET');

// API - COURIER GROUPS
export const getGroupList = createAction('GET_GROUP_LIST');
export const getGroupListSuccess = createAction('GET_GROUP_LIST_SUCCESS');
export const getGroupListFailure = createAction('GET_GROUP_LIST_FAILURE');

export const createGroup = createAction('CREATE_GROUP');
export const createGroupSuccess = createAction('CREATE_GROUP_SUCCESS');
export const createGroupFailure = createAction('CREATE_GROUP_FAILURE');
export const createGroupReset = createAction('CREATE_GROUP_RESET');

export const updateGroup = createAction('UPDATE_GROUP');
export const updateGroupSuccess = createAction('UPDATE_GROUP_SUCCESS');
export const updateGroupFailure = createAction('UPDATE_GROUP_FAILURE');
export const updateGroupReset = createAction('UPDATE_GROUP_RESET');

export const deleteGroup = createAction('DELETE_GROUP');
export const deleteGroupSuccess = createAction('DELETE_GROUP_SUCCESS');
export const deleteGroupFailure = createAction('DELETE_GROUP_FAILURE');
export const deleteGroupReset = createAction('DELETE_GROUP_RESET');

// API - COURIERS
export const getCourierList = createAction('GET_COURIER_LIST');
export const getCourierListSuccess = createAction('GET_COURIER_LIST_SUCCESS');
export const getCourierListFailure = createAction('GET_COURIER_LIST_FAILURE');

export const createCourier = createAction('CREATE_COURIER');
export const createCourierSuccess = createAction('CREATE_COURIER_SUCCESS');
export const createCourierFailure = createAction('CREATE_COURIER_FAILURE');
export const createCourierReset = createAction('CREATE_COURIER_RESET');

export const updateCourier = createAction('UPDATE_COURIER');
export const updateCourierSuccess = createAction('UPDATE_COURIER_SUCCESS');
export const updateCourierFailure = createAction('UPDATE_COURIER_FAILURE');
export const updateCourierReset = createAction('UPDATE_COURIER_RESET');

// API - COURIERS LAST LOCATIONS
export const getLastLocationList = createAction('GET_LAST_LOCATION_LIST');
export const getLastLocationListSuccess = createAction('GET_LAST_LOCATION_LIST_SUCCESS');
export const getLastLocationListFailure = createAction('GET_LAST_LOCATION_LIST_FAILURE');
export const gotLastLocation = createAction('GOT_LAST_LOCATION');

// API - ORDERS
export const getOrder = createAction('GET_ORDER');
export const getOrderSuccess = createAction('GET_ORDER_SUCCESS');
export const getOrderFailure = createAction('GET_ORDER_FAILURE');
export const getLocationOrderSuccess = createAction('GET_LOCATION_ORDER_SUCCESS');
export const resetOrder = createAction('RESET_ORDER');

export const getOrderList = createAction('GET_ORDER_LIST');
export const getOrderListSuccess = createAction('GET_ORDER_LIST_SUCCESS');
export const getOrderListFailure = createAction('GET_ORDER_LIST_FAILURE');

export const getOrderListReset = createAction('GET_ORDER_LIST_RESET');
export const getOrderListPage = createAction('GET_ORDER_LIST_PAGE');

// API - DELIVERY STAT
export const getDeliveryStatDayList = createAction('GET_DELIVERY_STAT_DAY_LIST');
export const getDeliveryStatDayListSuccess = createAction('GET_DELIVERY_STAT_DAY_LIST_SUCCESS');
export const getDeliveryStatDayListFailure = createAction('GET_DELIVERY_STAT_DAY_LIST_FAILURE');
export const getDeliveryStatDayListReset = createAction('GET_DELIVERY_STAT_DAY_LIST_RESET');

// API - AVAILABLE ORDER STATUS
export const getAvailableOrderStatuses = createAction('GET_AVAILABLE_ORDER_STATUS');
export const getAvailableOrderStatusesSuccess = createAction('GET_AVAILABLE_ORDER_STATUS_SUCCESS');
export const getAvailableOrderStatusesFailure = createAction('GET_AVAILABLE_ORDER_STATUS_FAILURE');

export const updateAvailableOrderStatuses = createAction('UPDATE_AVAILABLE_ORDER_STATUSES');
export const updateAvailableOrderStatusesSuccess = createAction('UPDATE_AVAILABLE_ORDER_STATUSES_SUCCESS');
export const updateAvailableOrderStatusesFailure = createAction('UPDATE_AVAILABLE_ORDER_STATUSES_FAILURE');
export const resetUpdateAvailableOrderStatuses = createAction('RESET_UPDATE_AVAILABLE_ORDER_STATUSES');

export const resetAvailableOrderStatuses = createAction('RESET_AVAILABLE_ORDER_STATUSES');

// API External Auth Token
export const getExternalAuthTokenList = createAction('GET_EXTERNAL_AUTH_TOKEN_LIST');
export const getExternalAuthTokenListSuccess = createAction('GET_EXTERNAL_AUTH_TOKEN_LIST_SUCCESS');
export const getExternalAuthTokenListFailure = createAction('GET_EXTERNAL_AUTH_TOKEN_LIST_FAILURE');

export const updateExternalAuthToken = createAction('UPDATE_EXTERNAL_AUTH_TOKEN');
export const updateExternalAuthTokenSuccess = createAction('UPDATE_EXTERNAL_AUTH_TOKEN_SUCCESS');
export const updateExternalAuthTokenFailure = createAction('UPDATE_EXTERNAL_AUTH_TOKEN_FAILURE');
export const updateExternalAuthTokenReset = createAction('UPDATE_EXTERNAL_AUTH_TOKEN_RESET');

export const createExternalAuthToken = createAction('CREATE_EXTERNAL_AUTH_TOKEN');
export const createExternalAuthTokenSuccess = createAction('CREATE_EXTERNAL_AUTH_TOKEN_SUCCESS');
export const createExternalAuthTokenFailure = createAction('CREATE_EXTERNAL_AUTH_TOKEN_FAILURE');
export const createExternalAuthTokenReset = createAction('CREATE_EXTERNAL_AUTH_TOKEN_RESET');

export const deleteExternalAuthToken = createAction('DELETE_EXTERNAL_AUTH_TOKEN');
export const deleteExternalAuthTokenSuccess = createAction('DELETE_EXTERNAL_AUTH_TOKEN_SUCCESS');
export const deleteExternalAuthTokenFailure = createAction('DELETE_EXTERNAL_AUTH_TOKEN_FAILURE');
export const deleteExternalAuthTokenReset = createAction('DELETE_EXTERNAL_AUTH_TOKEN_RESET');

// API Binded External Auth Token
export const getBindedExternalAuthToken = createAction('GET_BINDED_EXTERNAL_AUTH_TOKEN');
export const getBindedExternalAuthTokenSuccess = createAction('GET_BINDED_EXTERNAL_AUTH_TOKEN_SUCCESS');
export const getBindedExternalAuthTokenFailure = createAction('GET_BINDED_EXTERNAL_AUTH_TOKEN_FAILURE');

export const bindSubCompanyToExternalAuthToken = createAction('BIND_SUB_COMPANY_TO_EXTERNAL_AUTH_TOKEN');
export const bindSubCompanyToExternalAuthTokenSuccess = createAction('BIND_SUB_COMPANY_TO_EXTERNAL_AUTH_TOKEN_SUCCESS');
export const bindSubCompanyToExternalAuthTokenFailure = createAction('BIND_SUB_COMPANY_TO_EXTERNAL_AUTH_TOKEN_FAILURE');
export const bindSubCompanyToExternalAuthTokenReset = createAction('BIND_SUB_COMPANY_TO_EXTERNAL_AUTH_TOKEN_RESET');

export const unbindSubCompanyFromExternalAuthToken = createAction('UNBIND_SUB_COMPANY_FROM_EXTERNAL_AUTH_TOKEN');
export const unbindSubCompanyFromExternalAuthTokenSuccess = createAction(
    'UNBIND_SUB_COMPANY_FROM_EXTERNAL_AUTH_TOKEN_SUCCESS'
);
export const unbindSubCompanyFromExternalAuthTokenFailure = createAction(
    'UNBIND_SUB_COMPANY_FROM_EXTERNAL_AUTH_TOKEN_FAILURE'
);
export const unbindSubCompanyFromExternalAuthTokenReset = createAction(
    'UNBIND_SUB_COMPANY_FROM_EXTERNAL_AUTH_TOKEN_RESET'
);

export const resetBindedExternalAuthToken = createAction('RESET_BINDED_EXTERNAL_AUTH_TOKEN');

// API SERVER VERSION
export const getServerVersionSuccess = createAction('GET_SERVER_VERSION_SUCCESS');
export const getServerVersionFailure = createAction('GET_SERVER_VERSION_FAILURE');

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
