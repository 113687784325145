import { get, omit, pick } from 'lodash';
import { getContext, put, takeLatest } from 'redux-saga/effects';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';

// WATCHER
export function* subCompanyZoneSaga() {
    yield takeLatest(actions.getSubCompanyZone, getSubCompanyZone);
    yield takeLatest(actions.createSubCompanyZone, createSubCompanyZoneFromKML);
    yield takeLatest(actions.updateSubCompanyZoneUsingKml, updateSubCompanyZone);
    yield takeLatest(actions.deleteSubCompanyZone, deleteSubCompanyZone);
}

export function* getSubCompanyZone({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_SUB_COMPANY_ZONE, payload);

        const polygon = get(res, 'body.subCompanyZone', []);
        yield put(actions.getSubCompanyZoneSuccess(polygon));
    } catch (err) {
        yield put(actions.getSubCompanyZoneFailure(`${err}`));
    }
}

export function* createSubCompanyZoneFromKML({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.CREATE_SUB_COMPANY_ZONE, payload);

        const polygon = get(res, 'body', []);
        yield put(actions.createSubCompanyZoneSuccess(polygon));
    } catch (err) {
        yield put(actions.createSubCompanyZoneFailure(`${err}`));
    }
}

export function* updateSubCompanyZone({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    const requestMethod = payload?.kmlFileBody
        ? FC_REQUESTS.UPDATE_SUB_COMPANY_ZONE
        : FC_REQUESTS.UPDATE_SUB_COMPANY_ZONE_WITHOUT_KML;

    const requestData = payload?.kmlFileBody
        ? pick(payload, ['color', 'kmlFileBody', 'isDisabled', 'subCompanyZoneId', 'name'])
        : omit(payload, ['kmlFileBody']);

    try {
        const res = yield fastCityService.request(requestMethod, requestData);

        const polygon = get(res, 'body', []);

        yield put(actions.updateSubCompanyZoneUsingKmlSuccess(polygon));
    } catch (err) {
        console.error(err);
        yield put(actions.updateSubCompanyZoneUsingKmlFailure(`${err}`));
    }
}

function* deleteSubCompanyZone({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        yield fastCityService.request(FC_REQUESTS.DELETE_SUB_COMPANY_ZONE, payload);

        yield put(actions.deleteSubCompanyZoneSuccess(payload));
    } catch (err) {
        console.error(err);
        yield put(actions.deleteSubCompanyZoneFailure());
    }
}
